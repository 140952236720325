
import { defineComponent, ref, onMounted } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import State from "@/components/reusable/State.vue";
import Address from "@/components/reusable/Address.vue";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import ContentsBuildingModal from "@/components/modals/ContentsBuildingModal.vue";
import ScheduleInfoModal from "@/components/modals/ScheduleInfoModal.vue";
import Generator from "@/components/reusable/LegacyGenerator.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import Insurer from "@/components/reusable/Insurer.vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import BuildingTypes from "@/views/reusable/buy-insurance/house-insurance/building-types.json";

export default defineComponent({
  name: "legacy-owned-building-house-insurance-create-component",
  components: {
    Field,
    ErrorMessage,
    State,
    Address,
    ImagesUploader,
    ContentsBuildingModal,
    ScheduleInfoModal,
    Generator,
    Insurer,
    GoBackButton,
  },
  data() {
    return {
      buildingTypes: BuildingTypes,
      clientPublicId: "",
      policyNumber: "",
      businessType: "",
      policyStartDate: "",
      policyEndDate: "",
      underwriterPublicId: "",
      totalSumInsured: "",
      totalPremium: "",
      buildingType: "",
      brokerReference: "",
      state: "",
      localGovernmentArea: "",
      ward: "",
      streetAddress: "",
      buildingReinstatementValue: "",
      lossOfRentValue: "",
      imagesOfBuilding: "",
      houseHoldGoodsAndPersonalEffectsValue: "",
      cashValue: "",
      thirdPartyLiabilityLimit: "",
      benefitLimit: "",
      medicalExpenses: "",
      policyDocument: "",
      paymentReceipt: "",
      buildingDescription: "",
    };
  },
  setup() {
    const underwriters = ref([]);
    const clients = ref([{ publicId: "", fullName: "" }]);

    const getUnderwriters = () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.SUPERADMIN_UNDERWRITERS_LIST_ROUTE)
          .then(({ data }) => {
            underwriters.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const getLegacyClients = () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.LEGACY_CLIENT)
          .then(({ data }) => {
            clients.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const numberFormatter = () => {
      const ids = [
        "total-sum-insured",
        "total-premium-value",
        "benefit-limit",
        "building-reinstatement",
        "loss-of-rent",
        "general-household-goods",
        "medical-expenses-premium",
        "owned-third-party-value",
        "owned-cash-value",
      ];
      ids.forEach((id: string) => {
        variables.numberFormatter(id);
      });
    };

    onMounted(() => {
      getUnderwriters();
      getLegacyClients();
      numberFormatter();
    });

    return { underwriters, clients };
  },
  created() {
    //Set page title
    document.title =
      "Legacy: Owned Building House Insurance | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getUnderwriterId(underwriterId) {
      this.underwriterPublicId = underwriterId;
    },
    policyImageSelected(event, name) {
      this[name] = event.target.files[0];
    },
    formSubmit(e) {
      e.preventDefault();
      const submitButton1 = document.getElementById("home_owned_submit_btn");

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        const form = document.forms[0];
        const formData = new FormData(form);
        formData.append("package", "HOO");
        formData.append("packageType", "OWN");
        formData.append("businessClass", "HOM");
        formData.append("businessType", "HOU");

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(variables.LEGACY_POLICY, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then((response) => {
              submitButton1.removeAttribute("data-kt-indicator");
              variables.toastAlert(response.data.data.message, "success");
              this.$router.push({
                name: "admin-policy-migration-list",
              });
            })
            .catch(function(error) {
              submitButton1.removeAttribute("data-kt-indicator");
              console.info(error);
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      }
    },
  },
});
