
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import variables from "@/router/api";
import $ from "jquery";
import ResusableFunctions from "@/composables/ReusableFunctions";
import Generators from "@/views/reusable/buy-insurance/house-insurance/generators.json";

export default defineComponent({
  name: "legacy-generator-component",
  components: { Field, ErrorMessage },
  props: {
    valueClasses: String,
    brandClasses: String,
    photoClasses: String,
    photoReceiptClasses: String,
    existingGenerators: Array,
  },
  data() {
    return {
      generatorLists: Generators,
      generators: [
        {
          generatorValue: "",
          generatorBrand: "",
          generatorPhoto: "",
          generatorReceiptPhoto: "",
        },
      ],
    };
  },
  setup() {
    const { isEmptyArray } = ResusableFunctions();

    return {
      isEmptyArray,
    };
  },
  created() {
    if (
      this.existingGenerators !== undefined &&
      !this.isEmptyArray(this.existingGenerators)
    ) {
      this.generators = [...this.existingGenerators] as any;
    }
  },
  methods: {
    premium(event) {
      // Add comma to number value field
      variables.numberFormatter(event.target.id);
    },
    addDynamicField() {
      this.generators.push({
        generatorValue: "",
        generatorBrand: "",
        generatorPhoto: "",
        generatorReceiptPhoto: "",
      });
    },
    removeDynamicField(counter) {
      // Remove dynamic field
      $(".remove-" + counter).remove();
    },
    imagesSelected(event, position) {
      this.generators[position].generatorPhoto = event.target.files;
    },
    receiptsSelected(event, position) {
      this.generators[position].generatorReceiptPhoto = event.target.files;
    },
  },
});
