
import { defineComponent } from "vue";
import ExcelFileReader from "@/components/reusable/ExcelFileReader.vue";

export default defineComponent({
  name: "policy-migration-house-insurance-upload-schedule-component",
  props: {
    busienssType: String,
    businessClass: String
  },
  components: { ExcelFileReader },
  created() {
    //Set page title
    document.title =
      "Legacy: House Insurance Upload Schedule | " + process.env.VUE_APP_TITLE;
  }
});
