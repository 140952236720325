
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import HouseInsurance from "@/components/migration/packages/house-insurance/building/Quote.vue";
import variables from "@/router/api";

export default defineComponent({
  name: "admin-policy-migration-house-creation",
  components: { HouseInsurance },
  data() {
    return {
      profileCreation: variables.SUPERADMIN_CLIENT_ROUTE,
      redirect: "admin-policy-migration-creation",
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("House Insurance", [
        "Policy Migration",
        "Create Migration",
      ]);
    });
  },
  created() {
    //Set page title
    document.title =
      "Administrator: Create House Insurance | " + process.env.VUE_APP_TITLE;
  },
});
