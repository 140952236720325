
import { defineComponent } from "vue";
import OwnBuilding from "@/components/migration/packages/house-insurance/building/partials/OwnBuilding.vue";
import RentBuilding from "@/components/migration/packages/house-insurance/building/partials/RentBuilding.vue";
import UploadSchedule from "@/components/migration/packages/house-insurance/building/partials/UploadSchedule.vue";
import { Field } from "vee-validate";

export default defineComponent({
  name: "building-house-insurance",
  components: {
    Field,
    OwnBuilding,
    RentBuilding,
    UploadSchedule,
  },
  data() {
    return {
      buildingChoice: false,
      buildingChoice2: false,
      buildingChoice3: false,
    };
  },
  created() {
    //Set page title
    document.title =
      "Legacy House Insurance Selection | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    ownerSelection() {
      this.buildingChoice = true;
      this.buildingChoice2 = false;
      this.buildingChoice3 = false;
    },

    rentSelection() {
      this.buildingChoice2 = true;
      this.buildingChoice = false;
      this.buildingChoice3 = false;
    },

    uploadSelection() {
      this.buildingChoice3 = true;
      this.buildingChoice = false;
      this.buildingChoice2 = false;
    },
  },
});
